import {mdiLightbulb,mdiWeatherNight} from "@mdi/js"
export const BINGO_GOODS_TITLE_KEY = {
    // SHIBI
    SHIBI: ['SHIB'],
}
export const CACHE_KEY = {
    // list
    ANONYMITY_CART: 'anonymity_cart',
    ANONYMITY_CART_USER_ID: 'anonymity_cart_uid',
    // false
    ANONYMITY_CART_FLAG: 'anonymity_cart_flag',
    // 推荐码
    REFERRAL_CODE: 'referralCode',
    // 第三方登录
    LOGIN_TYPE: 'loginType',
}
export const ICON_NAME = {
    // list
    darkThemeIcon:{
        // 页面模式切换 to
        night:mdiLightbulb,
        day:mdiWeatherNight,
    },
}
/**
 * 对战类型人数
 */
export const BATTLE_TYPE_ENUM = {
    // number teamNumber
    // totalNumber number*2
    1: {
        battleType: 1,

        number: 1,
        totalNumber: 2,
    },
    2: {
        battleType: 2,
        number: 2,
        totalNumber: 4,
    },
}
export const BUS_NAME = {
    // 全局登录注册忘记密码弹窗
    SHOW_LOGIN: "showLogin0",
    // 全局聊天弹窗
    TO_CHAT: "toChat",
    // 新手引导激活
    SHEPHERD_ACTIVE: "shepherdActive",
    // 展示充值框
    SHOW_RECHARGE: "showRecharge",
    SHOW_DEPOSIT: "showDeposit",
    SHOW_HELP_BOX: "showHelpBox",

}
