import request from '@/utils/request'

// 获得客户的随机种子
export function userSeed(params) {
  return request({
    url: '/app-api/game/user-seed/get',
    method: 'GET',
    params
  })
}


//获取对战公平说明弹窗--获得对战盒子轮回列表
export function battleRound(params) {
  return request({
    url: '/app-api/game/battle/statics',
    method: 'GET',
    params
  })
}
//获取对战--轮回弹窗-每轮结果
export function battleRoundInfo(params) {
  return request({
    url: '/app-api/game/battle-round',
    method: 'GET',
    params
  })
}

//获取开盒物品用户总价排行
export function getLeaderboardList(query) {
  return request({
    url: '/app-api/game/unboxing/leaderboard',
    method: 'get',
    params: query
  })
}
//获取开盒物品用户总价排行-cache
export function getLeaderboardListLast(query) {
  return request({
    url: '/app-api/game/unboxing/leaderboard/last',
    method: 'get',
    params: query
  })
}


//获取不同开启的盲盒信息
export function getUnboxBoxDataByBoxCode(params) {
  return request({
    url: `/app-api/game/unboxing/livedrop/box`,
    method: 'GET',
    params
  })
}

// getLiveDrop
export function getLiveDrop(params) {
  return request({
    url: `/app-api/game/unboxing/livedrop`,
    method: 'GET',
    params
  })
}

// getLiveDropRandLucky
export function getLiveDropRandLucky(params) {
  return request({
    url: `/app-api/game/unboxing/livedrop/randlucky`,
    method: 'GET',
    params
  })
}