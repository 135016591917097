/** 公共方法类 */


/**
 * 校验文字是否包含关键字 => onlibox Bot 包含 bot 不行。onlibox 包含onlibox 不行
 * @param {*} keywordList 
 * @param {*} nickname 
 * @returns 
 */
export function textIncludesKey (keywordList, nickname) {
  function isFuzzyMatch (keyword, text) {
    return text.toLowerCase().includes(keyword.toLowerCase());
  }
  for (const keyword of keywordList) {
    if (isFuzzyMatch(keyword, nickname)) {
      return true;
    }
  }
  return false;
}


const USER_TAG = "ONLIBOX-LITE"
/**
 * 设用用户标识符号
 * 没有自动填充
 */
export function getUserTag () {
  return {
    uuid: localStorage.getItem(USER_TAG) || ''
  }
}
export function getUserTagIfNullGenrate () {
  let generate = false
  let uuid = localStorage.getItem(USER_TAG) || ''
  if ((uuid ?? '') === '') {
    uuid = uuidv4();
    generate = true
    localStorage.setItem(USER_TAG, uuid)
  }
  return {
    uuid,
    generate
  }
}
export function random (min, max) {
  return Math.round(Math.random() * (max - min)) + min;
}
export function randomStr (length) {
  let str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (var i = length; i > 0; --i)
    result += str[Math.floor(Math.random() * str.length)];
  return result;
}
export function randomHexStr (length) {
  let str = '0123456789abcdef';
  let result = '';
  for (var i = length; i > 0; --i)
    result += str[Math.floor(Math.random() * str.length)];
  return result;
}
export function uuidv4 () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}



/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams (params) {
  let result = ''
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    var part = encodeURIComponent(propName) + "=";
    if (value !== null && typeof (value) !== "undefined") {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && typeof (value[key]) !== 'undefined') {
            let params = propName + '[' + key + ']';
            var subPart = encodeURIComponent(params) + "=";
            result += subPart + encodeURIComponent(value[key]) + "&";
          }
        }
      } else {
        result += part + encodeURIComponent(value) + "&";
      }
    }
  }
  return result
}


/**
 * Url 替换超链接
 *
 * @param {String} text 文本
 * @param {String} color 超链接颜色
 */
export function textReplaceLink (text, color = '#409eff') {
  let exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
  return text.replace(
    exp,
    `<a href='$1' target="_blank" style="color:${color};text-decoration: revert;">$1</a >`
  )
}
export function copyText (text) {
  // Navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    navigator.clipboard.writeText(text);
  } else {
    // Use the 'out of viewport hidden text area' trick
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Move textarea out of the viewport so it's not visible
    textArea.style.position = "absolute";
    textArea.style.left = "-999999px";

    document.body.prepend(textArea);
    textArea.select();

    try {
      document.execCommand('copy');
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }
}
/**
 * 防抖原理：一定时间内，只有最后一次操作，再过wait毫秒后才执行函数
 *
 * @param {Function} func 要执行的回调函数
 * @param {Number} wait 延时的时间
 * @param {Boolean} immediate 是否立即执行
 * @return null
 */
let timeout = null

export function debounce (func, wait = 500, immediate = false) {
  // 清除定时器
  if (timeout !== null) clearTimeout(timeout)
  // 立即执行，此类情况一般用不到
  if (immediate) {
    const callNow = !timeout
    timeout = setTimeout(() => {
      timeout = null
    }, wait)
    if (callNow) typeof func === 'function' && func()
  } else {
    // 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
    timeout = setTimeout(() => {
      typeof func === 'function' && func()
    }, wait)
  }
}
let timer; let flag
/**
 * 节流原理：在一定时间内，只能触发一次
 *
 * @param {Function} func 要执行的回调函数
 * @param {Number} wait 延时的时间
 * @param {Boolean} immediate 是否立即执行
 * @return null
 */
export function throttle (func, wait = 500, immediate = true) {
  if (immediate) {
    if (!flag) {
      flag = true
      // 如果是立即执行，则在wait毫秒内开始时执行
      typeof func === 'function' && func()
      timer = setTimeout(() => {
        flag = false
      }, wait)
    }
  } else if (!flag) {
    flag = true
    // 如果是非立即执行，则在wait毫秒内的结束处执行
    timer = setTimeout(() => {
      flag = false
      typeof func === 'function' && func()
    }, wait)
  }
}
/**
 * @description 数字格式化 默认向下取整
 * @param {number|string} number 要格式化的数字
 * @param {number} decimals 保留几位小数
 * @param {boolean} formatZoreFlag 是否保留末尾的0
 * @param {string} decimalPoint 小数点符号
 * @param {string} thousandsSeparator 千分位符号
 * @returns {string} 格式化后的数字
 */
export function priceFormat (number, decimals = 2, formatZoreFlag = false, decimalPoint = '.', thousandsSeparator = ',') {
  if (decimals == 0) {
    return Number(number.toFixed(0)).toLocaleString()
  }
  return number.toLocaleString()
}

export function getBreakpoint() {
  const width = window.innerWidth;

  let breakpoint;
  if (width < 768) {
    breakpoint = 'sm';
  } else if (width < 1024) {
    breakpoint = 'md';
  } else if (width < 1280) {
    breakpoint = 'lg';
  } else {
    breakpoint = 'xl';
  }

  const breakpoints = ['sm', 'md', 'lg', 'xl'];
  console.log({breakpoint})
  return {
    current: breakpoint,
    is: (minBreakpoint) => breakpoints.indexOf(breakpoint) >= breakpoints.indexOf(minBreakpoint),
  };
}