import request from '@/utils/request'

// 注册
export function register(data) {
  return request({
    url: '/app-api/member/auth/email/register',
    method: 'POST',
    data
  })
}
// 注册 快速领取方式
export function registerClaim(data) {
  return request({
    url: '/app-api/member/auth/email/register/claim',
    method: 'POST',
    data
  })
}
export function emailNoActiveSelect(data) {
  return request({
    url: '/app-api/member/auth/email/register/check',
    method: 'POST',
    params:data
  })
}
export function emailNoActiveDestroy(data) {
  return request({
    url: '/app-api/member/auth/email/register/destroy',
    method: 'POST',
    params:data
  })
}
export function getNicknameAvailable(params) {
  return request({
    url: '/app-api/member/user/nickname-available',
    method: 'GET',
    params
  })
}
export function referralCodeCheck(referralCode) {
  return request({
    url: `/app-api/member/auth/referral/${referralCode}`,
    method: 'GET',
  })
}

export const refreshToken = (refreshToken) => {
  return request({
    url: `/app-api/member/auth/refresh-token?refreshToken=${refreshToken}`,
    method: 'POST'
  })
}
// 登录（使用手机 + 密码登录）
export function login(data) {
  return request({
    url: '/app-api/member/auth/login',
    method: 'POST',
    data
  })
}

//退出登录
export function logout() {
  return request({
    url: '/app-api/member/auth/logout',
    method: 'POST',
  })
}

// 社交授权的跳转
export function authRedirect(params) {
  return request({
    url: '/app-api/member/auth/social-auth-redirect',
    method: 'GET',
    params
  })
}

// 社交快捷登录，使用 code 授权码 (适合未登录的用户，但是社交账号已绑定用户)
export function authLogin(data) {
  return request({
    url: '/app-api/member/auth/social-login',
    method: 'POST',
    data
  })
}

// 重置密码
export function authRePassword(data) {
  return request({
    url: '/app-api/member/auth/reset-password',
    method: 'POST',
    data
  })
}

//邮箱验证密码
export function restByEmail(data) {
  return request({
    url: '/app-api/member/user/forget-password',
    method: 'POST',
    data
  })
}
//邮箱重置密码/app-api/member/user/reset-pwd

export function emailToActivation(sign) {
  return request({
    url: `/app-api/member/user/email-activation?sign=${sign}`,
    method: 'POST',
  })
}//邮箱重置密码/app-api/member/user/reset-pwd

export function emailToPassword(data) {
  return request({
    url: '/app-api/member/user/reset-pwd',
    method: 'POST',
    data
  })
}
// 修改用户密码
export function authUpdatePassword(data) {
  return request({
    url: '/app-api/member/auth/update-password',
    method: 'POST',
    data
  })
}

// 获得基本信息
export function getAccountInfo() {
  return request({
    url: '/app-api/member/user/get',
    method: 'GET',
  })
}

// 查询上级信息
export function getRelationInfo() {
  return request({
    url: '/app-api/member/user/get/relation',
    method: 'GET',
  })
}

// 获得账户信息
export function getWealthAccountInfo() {
  return request({
    url: '/app-api/wealth/account/get',
    method: 'GET',
  })
}

//注销账户
export function delAccount() {
  return request({
    url: '/app-api/wealth/account/cancel',
    method: 'POST',
  })
}

//永久放弃账户
export function giveUpAccount() {
  return request({
    url: '/app-api/wealth/account/giveUpForever',
    method: 'POST',
  })
}


// 修改用户昵称和邮箱(urlencoded格式)
export function updateNicknameEmail(data) {
  return request({
    url: '/app-api/member/user/update-nickname-email-avatar',
    method: 'Put',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data
  })
}

// 随机用户头像
export function randomAvatar(data) {
  return request({
    url: '/app-api/member/user/random-avatar',
    method: 'GET',
    headers: { 'content-type': "multipart/form-data" },
    data
  })
}
export function updateAvatar(data) {
  return request({
    url: '/app-api/member/user/update-avatar',
    method: 'POST',
    headers: { 'content-type': "multipart/form-data" },
    data
  })
}
export function updateUserAvatarDefault(data) {
  return request({
    url: '/app-api/member/user/update-avatar/default',
    method: 'POST',
    headers: { 'content-type': "multipart/form-data" },
    data
  })
}

//获取用户好友列表
export function getFriendsList(params) {
  return request({
    url: '/app-api/chat/friend/list',
    method: 'GET',
    params
  })
}
//获取收货人收件地址
export function getDefaulAdd() {
  return request({
    url: '/app-api/member/consignee/get',
    method: 'GET'
  })
}
//更新收货人收件地址
export function updataAdd(data) {
  return request({
    url: '/app-api/member/consignee/update',
    method: 'PUT',
    data
  })
}
//创建收货人收件地址
export function createAdd(data) {
  return request({
    url: '/app-api/member/consignee/create',
    method: 'POST',
    data
  })
}
//获取客户随机种子
export function getHash(data) {
  return request({
    url: '/app-api/game/user-seed/get',
    method: 'GET',
    data
  })
}
//更新客户随机种子
export function updataHash(data) {
  return request({
    url: '/app-api/game/user-seed/change/client',
    method: 'PUT',
    data
  })
}
//更新服务器种子
export function updataServerHash(data) {
  return request({
    url: '/app-api/game/user-seed/change/server',
    method: 'PUT',
    data
  })
}
//购买经验
export function buyUserExp(data) {
  return request({
    url: '/app-api/member/user/buy-exp',
    method: 'PUT',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data
  })
}
//读取配置项
export function getConfig(data) {
  return request({
    url: `/app-api/site-config/${data}`,
    method: 'GET',
  })
}
//修改文件证书
export function editFile(data) {
  return request({
    url: '/app-api/member/user/update-cert',
    method: 'POST',
    headers: { "content-type": "multipart/form-data" },
    data
  })
}

