import Vue from 'vue'

import store from '@/store'
import axios from 'axios'
import {refreshToken} from "@/api/user";
import errorCode from '@/utils/errorCode'
import {tansParams} from "@/utils/functions";
import { BUS_NAME } from "@/utils/constants";

// let downloadLoadingInstance;
// console.log("import.meta.env.VITE_APP_BASE_API",import.meta.env.VITE_APP_BASE_API);
let isRefreshing = false // 标记是否正在刷新 token
let requests = [] // 存储待重发请求的数组
// 不弹窗
const API_ERR_CODE_NO_POP_ARR = [1023004001,1021007003,1021007004]
function executeRequests(index) {
	if (index < requests.length) {
		requests[index]().then(() => {
			executeRequests(index + 1)
		})
	} else {
		isRefreshing = false
		requests.splice(0, requests.length)
	}
}

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
	// axios中请求配置有baseURL选项，表示请求URL公共部分
	baseURL: import.meta.env.VITE_APP_BASE_API,
	// 超时 1000=1秒
	timeout: 300000
})

// request拦截器
service.interceptors.request.use(async config => {
	// 是否需要设置 token
	// const isToken = (config.headers || {}).isToken === false
	// if (getToken() && !isToken) {
	// config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
	// }
	let expiresTime = store.state.user?.authorization?.expiresTime
	if (expiresTime && !isRefreshing) {

		let now = new Date().getTime()
		if (now > expiresTime - 60 * 1000 * 5) {

		}
	}
	setAuthorizationBearer(config.headers)
	// get请求映射params参数
	if ((config.method === 'get' || config.method === 'delete') && config.params) {
		let url = config.url + '?' + tansParams(config.params);
		url = url.slice(0, -1);
		config.params = {};
		config.url = url;
	}

	return config
}, error => {

	Promise.reject(error)
})
function setAuthorizationBearer(headers){
	let authorization = store.state.user.authorization
	if(authorization && authorization.accessToken){
		headers.Authorization = 'Bearer '+ authorization.accessToken
	}
}
// 响应拦截器
service.interceptors.response.use(res => {

		// 未设置状态码则默认成功状态
	const code = res.data.code || 0;
	// 获取错误信息
	const msg = errorCode[code] || res.data.msg || errorCode['default']

	// 二进制数据则直接返回
	if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
		return res.data
	}
		// if(res.config.url == "/app-api/member/user/get"){
	// 	if(!parseInt(localStorage.getItem("codeTest"))){
	// 		localStorage.setItem("codeTest",401);
	// 		re_code = 401
	// 	}
	// }
	// console.log(`userInfo: `,store.state.user.userInfo)
	// console.log(`authorization: `,store.state.user.authorization)
	// let userId = store.state.user.userId
	// console.log({re_code})
	if (code === 401 || code === 402) {
		if(store.state?.user?.authorization?.refreshToken) {

		}
		// 无效的刷新令牌
		store.dispatch("user/logout");
		return Promise.reject(code)
	} else if (code === 500) {
		// Vue.prototype.$awn.warning("Something went wrong, please try again later")
		Vue.prototype.$awn.warning(msg)
		// Message({
		// 	message: msg,
		// 	type: 'error'
		// })
		return Promise.reject(new Error(msg))
	}
	// 0
	else {

		if(res.data && res.data.code!==0){
			if(res.data.code === 1004003009){
				//打开dialog
				Vue.prototype.$box.BoxDialog.show({
					title: "Tips",
					confirmText: "VERIFY",
					showCancel: false,
					message: "Please verify your email first to enjoy Full OnliBox experience. <br><br><strong>*MAKE SURE YOU CHECK YOUR JUNK MAILS</strong>",
					onCancel() {
					},
					onConfirm() {
					}

				});
			// 不存在的clash
			} else if(res.data.code == 1021003001) {
				window.location.href = "/clash"
			// sell 10 充值金额不足
			} else if(res.data.code == 1008009002) {
				Vue.prototype.$box.BoxDialog.show({
					title: "Tips",
					confirmText: "Deposit",
					showCancel: false,
					message: "Minimum <span class='px-1 mx-1 success rounded font-weight-bold'>$10</span> Deposit Required Before Selling Your Items Back.",
					onCancel() {
					},
					onConfirm() {
						Vue.prototype.$bus.$emit(BUS_NAME.SHOW_DEPOSIT, true)
					}
				});
			// sell 充值金额不足
			} else if(res.data.code == 1008009001) {
				Vue.prototype.$box.BoxDialog.show({
					title: "Tips",
					confirmText: "Deposit",
					showCancel: false,
					message: "Minimum Deposit Required Before Selling Your Items Back.",
					onCancel() {
					},
					onConfirm() {
						Vue.prototype.$bus.$emit(BUS_NAME.SHOW_DEPOSIT, true)
					}
				});

			// 不存在的盒子
			} else if(res.data.code == 1008010000) {
				if(import.meta.env.PROD) {
					window.location.href = import.meta.env.VITE_APP_DEFAULT_BOX_URL
				}
			} else if(API_ERR_CODE_NO_POP_ARR.includes(res.data.code)) {

			} else {
				Vue.prototype.$awn.warning(msg,{})
			}
			return Promise.reject(res.data)
		} else {
			return res.data
		}
	}
},
	error => {
		console.log('err' , error)
		// 这里会因为includes报错
		// let msg = error?.message || ''
		// if (message == "Network Error") {
		// 	message = "Connection Error";
		// } else if (message.includes("timeout")) {
		// 	message = "Timeout";
		// } else if (message.includes("Request failed with status code")) {
		// 	message = "API " + message.substr(message.length - 3) + "Error";
		// }
		Vue.prototype.$awn.warning("Something went wrong, please try again later")
		return Promise.reject(error)
	}
)

async function refreshTokenHandler(res) {

	isRefreshing = true

	isRefreshing = true
	let {data} = await refreshToken(store.state?.user?.authorization?.refreshToken)
	console.log(data)
	if (data) {
		store.dispatch("user/setAuthorization", data);
	} else {
		console.log("logout")
		// code==402
		store.dispatch("user/logout");
	}
	isRefreshing = false

	refreshToken(store.state?.user?.authorization?.refreshToken).then(refresh_res => {

		const {accessToken, refreshToken, expiresTime} = refresh_res.data
		store.dispatch("user/setAuthorization", refresh_res.data);
		setAuthorizationBearer(res.headers)
		// token 刷新后将数组的方法重新执行
		requests.forEach((cb) => cb(accessToken))
		requests = [] // 重新请求完清空
		return service.request(res.config)
	}).catch(err => {
		store.dispatch("user/logout");
		return Promise.reject(err)
	}).finally(() => {
		isRefreshing = false
	})
}

export default service
