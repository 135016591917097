import request from '@/utils/request'

export function deposit(data) {
  return request({
    url: '/app-api/wealth/transaction/deposit',
    method: 'POST',
    data,
  })
}

/**
 * freepay 下一次充值时间
 * @returns {*}
 */
export function getNextFreePayTime() {
  return request({
    url: '/app-api/wealth/transaction/getNextOnliBoxPayTime',
    method: 'get',
  })
}

// 信用卡充值
// export function depositWithCard(data) {
//   return request({
//     url: '/app-api/wealth/transaction/depositWithCard',
//     method: 'POST',
//     data,
//   })
// }

// 加密货币充值
// export function depositWithCrypto(data) {
//   return request({
//     url: '/app-api/wealth/transaction/depositWithCrypto',
//     method: 'POST',
//     data,
//   })
// }

// 获得交易记录分页
export function transactionPage(params) {
  return request({
    url: '/app-api/wealth/transaction/page',
    method: 'GET',
    params

  })
}

//获取货币汇率

export function getMoneyTax(params) {
  return request({
    url: '/app-api/wealth/transaction/getCryptoRate',
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    params
  })
}

//提现货币

export function getWithdraw(data) {
  return request({
    url: '/app-api/wealth/transaction/withdraw',
    method: 'POST',
    data
  })
}
