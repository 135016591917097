/*
 * https://github.com/Hardlygo/vuetify-notification
 * @Author: PENGZY
 * @since: 2020-03-16 09:44:57
 * @lastTime: 2020-03-16 11:15:34
 * @LastAuthor: Do not edit
 * @moto: Be Curious!
 * @message:
 */
import component from "./BoxDialog.vue";
import {mergeOptions} from "./VBoxPluginHelper";

let $vm;

const plugin = {
    install(vue, option = {}) {
        //try avoid  [Vuetify] Unable to locate target [data-app]
        // document.body.setAttribute('data-app', true);
        const BoxDialog = vue.extend(component);
        //初始化,单独地挂载组件到文档内
        if (!$vm) {
            $vm = new BoxDialog({
                // el: document.createElement('div'),
                vuetify: option.vuetify || {},
                propsData: {
                    message: "",
                    title: "",
                    titleIcon: "error"
                }
            });
        }

        const boxDialog = {
            show(options) {
                if (typeof options === "object") {
                    mergeOptions($vm, options);
                }
                if (typeof options === "object" && (options.onShow || options.onHide)) {
                    options.onShow && options.onShow();
                }
                //先取消组件原本的showValue的监听
                this.$watcher && this.$watcher();
                //监听函数返回值是取消监听函数
                this.$watcher = $vm.$watch("showValue", val => {
                    if (!val && options && options.onHide) {
                        options.onHide();
                    }
                });
                $vm.$off("on-show");
                $vm.$off("on-hide");

                $vm.$on("on-show", () => {
                    options && options.onShow && options.onShow();
                });

                $vm.$on("on-hide", () => {
                    options && options.onHide && options.onHide();
                });

                //取消原组件的监听事件
                $vm.$off("on-cancel");
                $vm.$off("on-confirm");
                //重新注册
                $vm.$on("on-cancel", () => {
                    $vm.showValue = false;
                    options && options.onCancel && options.onCancel();
                });
                $vm.$on("on-confirm", () => {
                    $vm.showValue = false;
                    options && options.onConfirm && options.onConfirm();
                });
                $vm.showValue = true;
            },
            hide() {
                $vm.showValue = false;
            },
            isVisible() {
                return $vm.showValue;
            }
        };
        // all vn's plugins are included in this.$box
        if (!vue.$box) {
            vue.prototype.$box = {
                BoxDialog: boxDialog
            };
        } else {
            vue.prototype.$box.BoxDialog = boxDialog;
        }

        vue.mixin({
            created: function () {
                this.$box = vue.prototype.$box;
            },
            mounted() {
                //渲染完毕再添加到文档流
                // document.querySelector("#app").appendChild($vm.$el)
                // 或者，在文档之外渲染并且随后挂载
                let component = $vm.$mount();
                document.querySelector("#app").appendChild(component.$el);
            }
        });
    }
};
export default plugin;
export const install = plugin.install;
