import { getConfig } from "@/api/user";
const state = {
  configList: {
    boxLazyUrl: 'https://onliboxs3.s3.us-west-1.amazonaws.com/public/base_data/box_lazy.png'
  },
  headerNoticeConfig: {
    key : 'notice_20231009_read',
    show: false,
  },
};
const mutations = {
  SET_CONFIG_LIST(state, data) {
    state.configList = data
  }

};
const actions = {
  getConfigList({ commit }) {

    getConfig("WEB").then(({ data }) => {
      commit('SET_CONFIG_LIST', data)
    }).catch((err) => {

    })
  }
}

const getters = {
  fontInfo(state) {
    return { fontName: state.configList.fontName, fontPath: state.configList.fontPath }
  },
  headerNoticeConfig: state=>state.headerNoticeConfig
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
