import config from "@/store/modules/configData";

const getters = {
  //私聊连接状态
  chatMessage:state=>state.chat.chatMessage,
  config:state=>state.config,
  user:state=>state.user,
  appData:state=>state.appData,
}
export default getters
