import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

import appData from './modules/appData'
import chat from './modules/chat'
import config from "./modules/configData"
import getters from './getters'
import mutations from './mutations'
import user from "./modules/user.js"

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    config,
    appData,
    chat,
    user
  },
  getters,
  mutations,
  plugins: [createPersistedState()]
})

export default store
