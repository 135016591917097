<template>
  <div>
    <v-dialog

        v-model="showValue"
        persistent
        :mini-width="minWidth"
        :max-width="maxWidth"
        :width="width"
         @click:outside="_outsideEvent"
        style="z-index:1099"
    >
      <v-card :elevation="0" color="dialog"
          elevation="0" rounded="0"
          v-if="type==='loading'"
      >
        <v-card-title class="font-weight-bold lighten-2 d-flex justify-space-between align-center">
          <div>{{ title }}</div>
        </v-card-title>
        <v-card-text>
          <div class="font-weight-bold" v-html="message"></div>
        </v-card-text>

      </v-card>
      <v-card v-if="type==='confirm'"  :elevation="0" color="dialog" rounded="0">

        <v-card-title class="font-weight-bold lighten-2 d-flex justify-space-between align-center">
          <div>{{ title }}</div>
          <icon class-name="pointer v-dialog-close" name="dialog-close" width="25px" height="25px" @click="_onCancel"></icon>
        </v-card-title>

        <v-card-text class="black--text">
          <div class="font-weight-bold" v-html="message"></div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn elevation="0"
                 v-if="showCancel"
                 color="grey"
                 dark
                 text
                 @click.stop="_onCancel"
          >
            {{ cancelText }}
          </v-btn>

          <v-btn elevation="0"
                 v-if="showConfirm"
                 color="black"
                 @click.stop="_onConfirm">
            {{ confirmText }}
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "m-dialog",

  data() {
    return {
      showValue: false
    }
  },
  props: {
    status: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "confirm"
    },
    maxWidth: {
      type: [String, Number],
      default: 400
    },
    minWidth: {
      type: [String, Number],
      default: 400
    },
    width: {
      type: [String, Number],
      default: 400
    },
    message: String,
    title: String,
    titleIcon: String,
    showConfirm: {
      type: Boolean,
      default: true
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    outside: {
      type: Boolean,
      default: true
    },
    titleBarColor: {
      type: String,
      default: "light-blue"
    },
    iconColor: {
      type: String,
      default: "blue"
    },
    confirmText: {
      type: String,
      default: "OK"
    },
    cancelText: {
      type: String,
      default: "Cancel"
    }
  },
  watch: {
    status: {
      handler: function (val) {
        this.showValue = val;
      },
      immediate: true
    },
    showValue(val) {
      //val为true表明打开
      if (val) {
        this._onShow();
      } else {
        this._onHide();
      }
    }
  },
  methods: {
    _outsideEvent() {
      if (this.outside) {
        this._onCancel()
      }
    },
    _onShow() {
      this.status = true;
      this.$emit("on-show");
    },
    _onHide() {
      this.status = false;
      this.$emit("on-hide");
    },
    _onCancel() {
      this.$emit("on-cancel");
    },
    _onConfirm() {
      this.$emit("on-confirm");
    }
  }
};
</script>
