const state = {
    chats: [],
    index: 0,
    unreadCount: 0,
    /**
     * 私聊 聊天已读未读 数
     */
    singleUnreadCountList: [],
    groupUnreadCount: 0,
    chatMessage: [],
    tempChatMessage: [],
}

const getters = {
    singleUnreadCountList: state => state.singleUnreadCountList,
    groupUnreadCount: state => state.groupUnreadCount,
}

const mutations = {
    SET_CHATS_HISTORY: (state, data) => {
        state.chats = data
    },
    SET_UNREAD_COUNT: (state, data) => {
        state.unreadCount = data
    },
    SET_CHAT_LAST: (state, data) => {
        state.lastMessage = data
    },
    SET_CHAT_MESSAGE: (state, data) => {
        state.chatMessage = data
    }
};

const actions = {
    // 添加group
    addGroupMessage({commit, state}, data) {
        let num = data?.num || 0

        state.groupUnreadCount+=num
    },
    readGroupMessage({commit, state}) {
        state.groupUnreadCount = 0
    },
    // 发送一条消息后设置为-1 服务器会转发回来
    sendGroupMessage({commit, state}) {
        state.groupUnreadCount = -1
    },
    // 添加新消息
    addSingleMessage({commit, state}, data) {
        if (state.singleUnreadCountList.length === 0) {
            state.singleUnreadCountList.push(data);
        } else {
            let index = state.singleUnreadCountList.findIndex(l => l.chatMsgSeiId === data.chatMsgSeiId)
            let item = state.singleUnreadCountList[index] || 0
            item.num++
            state.singleUnreadCountList[index] = item
        }
    },
    // 已读消息
    readSingleMessage({commit, state}, {chatMsgSeiId}) {
        let index = state.singleUnreadCountList.findIndex(l => l.chatMsgSeiId === chatMsgSeiId)
        if(index>-1){
            state.singleUnreadCountList.splice(index, 1);
        }

    },
    // 清空全部
    clearSingleMessage({commit, state}) {
        state.singleUnreadCountList = []
    },

    /**
     * 重新加载聊历史记录
     */
    reloadChats({commit}) {
        const str = localStorage.getItem("chat");
        const chats = str ? JSON.parse(str).chats : [];
        commit("SET_CHATS_HISTORY", chats)
    },
    /**
     * 重置未读消息计数
     */
    reset({commit}) {
        commit("SET_UNREAD_COUNT", 0)
    },
    /**
     * 添加消息
     * @param i 索引
     * @param chat 聊天室
     * @param message 消息
     * @private
     */
    /* addMessage({commit},data) {
       //chat, message
      // chat.unreadCount = 0;
       commit("SET_UNREAD_COUNT",0)
       commit("SET_CHAT_LAST",data.mine ? '' : data.content)
         this.addToMessageList(data);
     },*/
    //聊天对话框已经打开状态
    /*if (i === this.index) {

    }
    //聊天对话框是没有打开状态
    else {
      chat.unreadCount++;
      chat.lastMessage = (message.mine ? '' : message.content);
      this.addToMessageList(message, chat.id);
    }
  },*/

    clearMessageList({commit, state}, data) {
        state.chatMessage = []
    },
    addToMessageList({commit, state, rootState}, data) {
        const tmpMsgList = state.chatMessage;
        if (data && data.content) {
            if (data.content.startsWith("{") && data.content.endsWith("}")) {
                data.content = JSON.parse(data.content)
            }
            if (data.fromId == rootState.user.userId) {
                data.mine = true
            }
        }
        //const messageList = this.chatMessage.get(1) ?? [];
        // this.insertMessage(tmpMsgList, data);
        if (tmpMsgList && tmpMsgList.length === 0) {
            tmpMsgList.push(data);
            return;
        }
        //重复数据,不予处理
        const repeat = tmpMsgList.findIndex((item) => {
            return item.id === data.id;
        });
        if (repeat > -1) {
            // TODO 暂时这么处理，几个版本过了，直接return，删除下面的替换
            state.chatMessage[repeat] = data
            return;
        }
        //如果新消息的时间比历史数据的时间小
        const len = tmpMsgList.findIndex((n) => {
            return n.timestamp > data.timestamp;
        });
        //消息是在队列的中间，不在最后
        if (len > -1) {
            tmpMsgList.splice(len, 0, data);
        } else {
            tmpMsgList.push(data);
        }
        if (tmpMsgList.length > 100) {
            tmpMsgList.shift();
        }
        commit("SET_CHAT_MESSAGE", tmpMsgList)
        //this.chatMessage.set(1, messageList);

    },


}
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};

/*

export const useChatStore = {

  // 开启数据缓存
 /* persist: {
    enabled: true,
    strategies: [
      {
        key: "chat",
        storage: myLocalStoreUtils,
        paths: ["index", "chats"],
      },
    ],
  },*/
/* actions: {
   /**
    * 重新加载聊历史记录
    */
/* reloadChats() {
   const str = myLocalStoreUtils.getItem("chat");
   this.chats = str ? JSON.parse(str).chats : [];
 },*/
/*
/**
 * 把一个消息推送到对应的聊天记录里面
 * @param message 消息
 */
/* pushMessage(message) {
  this.addMessage(chat, message);
}, */
/**
 * 把数据插入到消息的列表，有序插入
 * @param list list
 * @param message 消息
 */
/* insertMessage(list, message) {
  if (list && list.length == 0) {
    list.push(message);
    return;
  }
  //重复数据,不予处理
  const repeat = list.findIndex((item) => {
    return item.id === message.id;
  });
  if (repeat > -1) {
    return;
  }
  //如果新消息的时间比历史数据的时间小
  const len = list.findIndex((n) => {
    return n.timestamp > message.timestamp;
  });
  //消息是在队列的中间，不在最后
  if (len > -1) {
    list.splice(len, 0, message);
  } else {
    list.push(message);
  }
},
/**
 * 创建一个新聊天室
 * @param message message
 * @private 私有方法
 */
/*createChatRoom(message) {
    if (message.type === ChatType.GROUP) {
      //先占位，后加载
      const chat = {
        id: message.chatId,
        name: "加载中...",
        avatar: "",
        type: ChatType.GROUP,
        lastMessage: message.content,
        unreadCount: 0,
        isLoading: false,
        loaded: true,
      };
      this.chats.unshift(chat);
      GroupApi.get(chat.id).then((group) => {
        const i = this.getChatIndex(chat.id);
        this.chats[i].name = group.data.name;
        this.chats[i].avatar = group.data.avatar;
      });
    } else {
      const chat = {
        id: message.chatId,
        name: message.name,
        avatar: message.avatar,
        type: ChatType.FRIEND,
        lastMessage: message.content,
        unreadCount: 0,
        isLoading: false,
        loaded: true,
      };
      this.chats.unshift(chat);
    }
  },
  */
/**
 * 添加消息
 * @param i 索引
 * @param chat 聊天室
 * @param message 消息
 * @private
 */
/* addMessage(i, chat, message) {
   //聊天对话框已经打开状态
   if (i === this.index) {
     chat.unreadCount = 0;
     chat.lastMessage = (message.mine ? '' : message.content);
     this.addToMessageList(message, chat.id);
   }
   //聊天对话框是没有打开状态
   else {
     chat.unreadCount++;
     chat.lastMessage = (message.mine ? '' : message.content);
     this.addToMessageList(message, chat.id);
   }
 },

 /**
  * 打开一个聊天对话框
  * @param chat chat
  */
/* openChat(chat) {
 let hasChat = false;
 this.chats.forEach((item, index) => {
   //聊天对话框已经存在
   if (item.id === chat.id) {
     this.index = index;
     item.unreadCount = 0;
     hasChat = true;
   }
 });  */
//新增一个新聊天对话框
/*if (!hasChat) {
  chat.unreadCount = 0;
  //添加到聊天列表第一个
  this.chats.unshift(chat);
  this.index = 0;
}*/
// },


/**
 * 限制聊天记录的数组的长度
 * @param message 新增的聊天消息
 * @param chatId 聊天室
 */
/*addToMessageList(message, chatId) {
  const messageList = this.chatMessage.get(chatId) ?? [];
  this.insertMessage(messageList, message);
  if (messageList.length > 100) {
    messageList.shift();
  }
  this.chatMessage.set(chatId, messageList);
},

},
}*/

