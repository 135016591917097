
import Vue from 'vue';
export const EventBus = new Vue();

export const WS_EVENT_KEY = {
    WS_EVENT_PING: "ws_event_ping",
    /**
    * 单聊 通知 10
    */
    WS_EVENT_SINGLE_NOTICE: "ws_event_single_notice",
    /**
     * 单聊已读 11 
     */
    WS_EVENT_SINGLE_READ: "ws_event_single_read",
    /**
    * 群聊 通知 20
    */
    WS_EVENT_GROUP_NOTICE: "ws_event_group_notice",
}
export const WS_EVENT_CODE = {
    WS_EVENT_PING: "0",
    /**
    * 单聊 通知 10
    */
    WS_EVENT_SINGLE_NOTICE: "10",
    /**
     * 单聊已读 11 
     */
    WS_EVENT_SINGLE_READ: "11",
    /**
    * 群聊 通知 20
    */
    WS_EVENT_GROUP_NOTICE: "20",
}
export let WS_EVENT_DICT = {}
for ( let key in WS_EVENT_CODE) {
    WS_EVENT_DICT[WS_EVENT_CODE[key]] = WS_EVENT_KEY[key]
}
// WS_EVENT_DICT[WS_EVENT_CODE.WS_EVENT_PING] = WS_EVENT_KEY.WS_EVENT_PING
// WS_EVENT_DICT[WS_EVENT_CODE.WS_EVENT_SINGLE_NOTICE] = WS_EVENT_KEY.WS_EVENT_SINGLE_NOTICE
// WS_EVENT_DICT[WS_EVENT_CODE.WS_EVENT_SINGLE_READ] = WS_EVENT_KEY.WS_EVENT_SINGLE_READ
// WS_EVENT_DICT[WS_EVENT_CODE.WS_EVENT_GROUP_NOTICE] = WS_EVENT_KEY.WS_EVENT_GROUP_NOTICE

