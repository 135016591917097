import store from '@/store'
import tio from './tiows'
let heartbeatTimeout = 1000 * 60; // 心跳超时时间，单位：毫秒
let reconnInterval = 1000; // 重连间隔时间，单位：毫秒

let binaryType = 'blob'; // 'blob' or 'arraybuffer';//arraybuffer是字节

let tiows
let open = false
import { WS_EVENT_KEY, WS_EVENT_CODE, WS_EVENT_DICT, EventBus } from "./ws.event"
/**
 * 消息前置处理
 */
function onmessageBefore (code, message) {
    if (code == WS_EVENT_CODE.WS_EVENT_SINGLE_NOTICE) {
        store.dispatch("chat/addSingleMessage", { chatMsgSeiId: message.chatMsgSeiId, num: message.num });
    } else if (code == WS_EVENT_CODE.WS_EVENT_GROUP_NOTICE) {
        store.dispatch("chat/addGroupMessage", { msgId: message.msgId, num: message.num });
    } else {

    }
}

class WsHandler {

    constructor() {
    }
    onopen (event, ws) {
        open = true
    }
    /**
     * 收到服务器发来的消息
     * @param {*} event
     * @param {*} ws
     */
    onmessage (event, ws) {
        let data = event.data
        let { code, message = {} } = JSON.parse(event.data)
        onmessageBefore(code, message)
        EventBus.$emit(WS_EVENT_DICT[code], message)
    }

    onclose (e, ws) {
        // error(e, ws)
        open = false
    }

    onerror (e, ws) {
        // error(e, ws)
        open = false
    }

    /**
     * 发送心跳，本框架会自动定时调用该方法，请在该方法中发送心跳
     * @param {*} ws
     */
    ping (ws) {
        // log("发心跳了")
        ws.send('{"code":"0"}')
    }
}

export function closeWs() {
    if(tiows && open){
        tiows.close()
    }
}

const plugin = {
    install (vue, option = {}) {

        const ws0 = {

            initWs () {
                if (open) {
                    return
                }
                let token = store.state.user?.authorization?.accessToken
                let host = `ws${window.location.protocol.length>5?'s':''}://${window.location.host}`
                
                if(import.meta.env.DEV) {
                    host = ''
                }
                let url = `${host}${import.meta.env.VITE_APP_WEB_SOCKET_URL}?token=Bearer ${token}`
                let handler = new WsHandler(vue)
                tiows = new tio.ws(url, {}, handler, heartbeatTimeout, reconnInterval, binaryType)
                tiows.connect()
            },
            $on (event, fun) {
                EventBus.$on(event, fun)
            },
            $emit (event, message) {
                EventBus.$emit(event, message)
            },
            $off (event) {
                EventBus.$off(event,)
            },
            // code,message
            send (content) {
                if (!open) {
                    this.initWs()
                }
                tiows.send(JSON.stringify(content))
            },
            close () {
                tiows.close()
            },
        }
        vue.prototype.$ws0 = ws0

    }

}

export default plugin;
export const install = plugin.install;
