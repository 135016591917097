import request from '@/utils/request'

// 获得分销等级列表
export function getLevelList(params) {
  return request({
    url: '/app-api/affiliate/level/list',
    method: 'GET',
    params
  })
}

// 获取上级的推荐码
export function getRelationCode() {
  return request({
    url: '/app-api/affiliate/relation/code',
    method: 'GET',

  })
}

// 获得分销组织分页
export function getAffiliatePage(params) {
  return request({
    url: '/app-api/affiliate/page',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    method: 'GET',
    params
  })
}

// 获得佣金账户
export function getCommissionAccount(params) {
  return request({
    url: '/app-api/commission/account/get',
    method: 'GET',
    params
  })
}

// 认领现金 // amount 金额	query
export function putClaim(data) {
  return request({
    url: '/app-api/commission/account/claim/amount',
    method: 'PUT',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data
  })
}

//根据日期筛选分销数据
export function byDate(params) {
  return request({
    url: '/app-api/affiliate/statics',
    method: 'GET',
    params
  })
}

// 设置邀请码 // referralCode 邀请码
export function setCode(data) {
  return request({
    url: '/app-api/commission/account/referral/code',
    method: 'PUT',
    data
  })
}

//查询推荐码对应详情
export function getCodeInfo(params) {
  return request({
    url: '/app-api/referral-code/get',
    method: 'GET',
    params
  })
}
//借钱
export function borrowMoney(data) {
  return request({
    url: '/app-api/commission/account/borrow/amount',
    method: 'PUT',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data
  })
}